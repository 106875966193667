import {Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {DataService} from '../../core/data.service';
import {select, Store} from '@ngrx/store';
import * as rootState from '../../store';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-tire',
  templateUrl: './tire.component.html'
})
export class TireComponent implements OnInit, OnChanges, OnDestroy {
  @Input() tire: any;
  @Input() images: any[];
  @Input() showPrice: boolean;

  loadingArticleNumber$: Observable<any>;
  workshop: any;
  imageUrl: string = null;
  showLarge: boolean;

  private workshopSubscription: Subscription;

  constructor(private _data: DataService, private store: Store<rootState.AppState>) {}

  ngOnInit(): void {
    this.loadingArticleNumber$ = this.store.pipe(select(rootState.getLoadingArticleNumber));
    this.handleWorkshop();
  }

  ngOnChanges(): void {
    if (this.images) {
      this.setImageUrl();
    }
  }

  toggleShowLargeImage(): void {
    this.showLarge = !this.showLarge;
  }

  onImageLoadError(image): void {
    image.src = 'assets/images/no_image.png';
  }

  ngOnDestroy(): void {
    if (this.workshopSubscription) {
      this.workshopSubscription.unsubscribe();
    }
  }

  private handleWorkshop(): void {
    this.workshopSubscription = this.store.pipe(
      select(rootState.getAuthUser),
      map((authUser) => this.workshop = authUser)
    ).subscribe();
  }

  private setImageUrl(): void {
    const name = this.tire.description
      .replace(/\s/g, '')
      .toLowerCase()
      .replace('runfla', '')
      .replace('runfl', '')
      .replace('sommer', '')
      .replace('dekk', '')
      .replace('pigg', '')
      .replace('fritt', '')
      .replace('vinter', '')
      .replace('+', 'plus'); 

    for (let i = 0; i < this.images.length; i++) {
      const image = this.images[i];

      if (image.Key.includes(`${name}`)) {
        this.imageUrl = `https://s3.eu-north-1.amazonaws.com/carsweb-images/${image.Key}`;
        break;
      }
    }
  }
}

import * as CartActions from './cart.actions';

export type Action = CartActions.All;

export interface CartState {
  items: any[];
  showCart: boolean;
}

export const initialState: CartState = {
  items: [],
  showCart: false,
};

export function reducer(state = initialState, {type, payload}: Action): CartState {
  switch (type) {
    case CartActions.ADD_TO_CART:
      return Object.assign({}, state, {
        items: [...state.items, payload]
      });
    case CartActions.REMOVE_FROM_CART:
      return Object.assign({}, state, {
        items: state.items.filter((item) => item.tire.article_number_with_alpha !== payload.tire.article_number_with_alpha)
      });
    case CartActions.TOGGLE_SHOW_CART:
      return Object.assign({}, state, {
        showCart: !state.showCart
      });
    case CartActions.INCREASE_CART_AMOUNT:
      return Object.assign({}, state, {
        items: state.items.map((item) => {
          if (item.tire.article_number_with_alpha === payload.article_number_with_alpha) {
            const oldAmount = item.amount;
            return Object.assign({}, item, {amount: oldAmount + 1});
          } else {
            return item;
          }
        })
      });
    case CartActions.DECREASE_CART_AMOUNT:
      return Object.assign({}, state, {
        items: state.items.map((item) => {
          if (item.tire.article_number_with_alpha === payload.article_number_with_alpha) {
            const oldAmount = item.amount;
            return Object.assign({}, item, {amount: oldAmount - 1});
          } else {
            return item;
          }
        })
      });
    case CartActions.RESET_CART:
      return Object.assign({}, state, {
        showCart: false,
        items: []
      });
    default:
      return state;
  }
}

export const getItems = (state) => state.items;
export const getShowCart = (state) => state.showCart;

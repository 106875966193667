import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as rootState from '../../store';
import {DecreaseCartAmount, IncreaseCartAmount, RemoveFromCart, ToggleShowCart} from '../../store/cart/cart.actions';
import {Observable, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {SendOrder, SendOrderSuccess} from '../../store/tire/tire.actions';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html'
})
export class CartComponent implements OnInit, OnDestroy {
  @Input() authUser: any;

  cartItems: any[];
  showCart$: Observable<boolean>;
  isSendingOrder: boolean;
  text: string;

  private cartItemsSubscription: Subscription;
  private sendingOrderSubscription: Subscription;

  constructor(private store: Store<rootState.AppState>) {}

  ngOnInit(): void {
    this.sendingOrderSubscription = this.store
      .pipe(select(rootState.getSendingOrderLoadingState))
      .subscribe((sendingOrder) => this.isSendingOrder = sendingOrder);

    this.showCart$ = this.store.pipe(select(rootState.getShowCart));

    this.cartItemsSubscription = this.store
      .pipe(
        select(rootState.getCartItems),
        map((items) => this.cartItems = items),
      )
      .subscribe();
  }

  increaseCartAmount(cartItem: any): void {
    if (cartItem.tire.details.available !== cartItem.amount) {
      this.store.dispatch(new IncreaseCartAmount(cartItem.tire));
    }
  }

  decreaseCartAmount(cartItem: any): void {
    if (cartItem.amount !== 1) {
      this.store.dispatch(new DecreaseCartAmount(cartItem.tire));
    }
  }

  removeCartItem(cartItem: any): void {
    this.store.dispatch(new RemoveFromCart(cartItem));
  }

  close(): void {
    this.store.dispatch(new ToggleShowCart());
  }

  sendOrder(): void {
    const data = {
      items: this.cartItems,
      wholesaleId: this.authUser.wholesaler_id,
      customerNo: this.authUser.customer_number,
      text: this.text,
    };

    this.store.dispatch(new SendOrder(data));
  }

  onImageError(image): void {
    image.src = 'assets/images/no_image.png';
  }

  ngOnDestroy(): void {
    if (this.cartItemsSubscription) {
      this.cartItemsSubscription.unsubscribe();
    }

    if (this.sendingOrderSubscription) {
      this.sendingOrderSubscription.unsubscribe();
    }
  }
}

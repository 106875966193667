import { BrowserModule } from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import {FormsModule} from '@angular/forms';
import {CoreModule} from './core/core.module';
import {HttpClientModule} from '@angular/common/http';
import {SharedModule} from './shared/shared.module';
import {NgxWebstorageModule} from 'ngx-webstorage';
import {LoginComponent} from './login/login.component';
import {StoreModule} from '@ngrx/store';
import {metaReducers, reducers} from './store';
import { EffectsModule } from '@ngrx/effects';
import {AuthEffects} from './store/auth/auth.effects';
import {TireEffects} from './store/tire/tire.effects';
import {CartEffects} from './store/cart/cart.effects';
import {registerLocaleData} from '@angular/common';
import localeNb from '@angular/common/locales/nb';
registerLocaleData(localeNb);


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxWebstorageModule.forRoot(),
    FormsModule,
    CoreModule,
    HttpClientModule,
    SharedModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot([
      AuthEffects,
      TireEffects,
      CartEffects
    ]),
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'nb' }],
  bootstrap: [AppComponent]
})
export class AppModule { }

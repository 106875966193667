import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-amount-selector',
  templateUrl: './amount-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AmountSelectorComponent {
  @Input() amount: number;
  @Output() amountChanged: EventEmitter<number> = new EventEmitter<number>();
  @Output() increaseAmount: EventEmitter<void> = new EventEmitter<void>();
  @Output() decreaseAmount: EventEmitter<void> = new EventEmitter<void>();

  onFocus(amountField): void {
    setTimeout(() => {
      amountField.select();
    }, 10);
  }
}

import * as AuthActions from './auth.actions';
import {AuthenticationState} from '../../enums';
import {handleError} from '../helpers';

type Action = AuthActions.All;

export interface AuthState {
  loading: boolean;
  authenticationState: AuthenticationState;
  authUser: any;
  error: string;
}

const initialState: AuthState = {
  loading: false,
  authenticationState: null,
  authUser: null,
  error: null,
};

export function reducer(state: AuthState = initialState, {type, payload}: Action): AuthState {
  switch (type) {
    case AuthActions.AUTHENTICATE:
      return Object.assign({}, state, {
        loading: true,
        authenticationState: AuthenticationState.AUTHENTICATING,
        error: null
      });
    case AuthActions.AUTHENTICATE_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        authUser: payload,
        authenticationState: AuthenticationState.AUTHENTICATED
      });
    case AuthActions.AUTHENTICATE_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        authenticationState: AuthenticationState.UNAUTHENTICATED,
        error: handleError(payload)
      });
    case AuthActions.CHECK_AUTH_SUCCESS:
      const authUser = (payload !== undefined) ? payload : null;

      return Object.assign({}, state, {
        loading: false,
        authUser: authUser,
        authenticationState: (authUser) ? AuthenticationState.AUTHENTICATED : AuthenticationState.UNAUTHENTICATED,
      });
    case AuthActions.UNAUTHENTICATE:
      return Object.assign({}, state, {
        loading: false,
        authUser: null,
        authenticationState: AuthenticationState.UNAUTHENTICATING,
      });
    case AuthActions.UNAUTHENTICATE_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        authUser: null,
        authenticationState: AuthenticationState.UNAUTHENTICATED,
      });
    default:
      return state;
  }
}

export const getLoadingState = (state: AuthState) => state.loading;
export const getAuthUser = (state: AuthState) => state.authUser;
export const getError = (state: AuthState) => state.error;
export const getAuthenticationState = (state: AuthState) => state.authenticationState;

import {Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {TireDimensions} from '../../interfaces';
import {select, Store} from '@ngrx/store';
import * as rootState from '../../store';
import {Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {SetDimensions} from '../../store/search/search.actions';

@Component({
  selector: 'app-dimensions-search-box',
  template: `
    <form #dimensionsSearchForm="ngForm" class="dimensions-search-box" (ngSubmit)="searchDimensions.emit(dimensions)" novalidate>
      <div class="dimensions-search-box-title">Søk etter dimensjoner</div>

      <div class="dimensions-search-box-input-wrapper">
        <div class="dimensions-search-box-input-label">Bredde</div>
        <input #widthInput type="text" name="width" autocomplete="off" maxlength="3" [ngModel]="dimensions?.width" required
               [disabled]="disabled" #widthInput (keyup)="onWidthChanged()" (focus)="onFocus(widthInput)"
               class="dimensions-search-box-input">
        <div class="dimensions-search-box-input-label">Høyde</div>
        <div class="dimensions-search-box-input-divider">/</div>
        <input #heightInput type="text" name="height" autocomplete="off" maxlength="2"
               [ngModel]="dimensions?.height" required [disabled]="disabled" (keyup)="onHeightChanged()"
               (focus)="onFocus(heightInput)" class="dimensions-search-box-input">
        <div class="dimensions-search-box-input-divider">R</div>
        <div class="dimensions-search-box-input-label">Diameter</div>
        <input #diameterInput type="text" name="diameter" autocomplete="off" maxlength="2"
               [ngModel]="dimensions?.diameter" required [disabled]="disabled" (focus)="onFocus(diameterInput)"
               class="dimensions-search-box-input" (ngModelChange)="onDiameterChanged()">
        <button type="submit" class="dimensions-search-box-button" [disabled]="!dimensionsSearchForm.form.valid">
          Søk
        </button>
      </div>
    </form>
  `
})
export class DimensionsSearchBoxComponent implements OnInit, OnDestroy {
  @ViewChild('widthInput') widthInput: ElementRef;
  @ViewChild('heightInput') heightInput: ElementRef;
  @ViewChild('diameterInput') diameterInput: ElementRef;
  @Input() disabled: boolean;
  @Output() searchDimensions: EventEmitter<TireDimensions> = new EventEmitter<TireDimensions>();

  dimensions: TireDimensions = {width: null, height: null, diameter: null};
  eventKey: any;

  private dimensionsSubscription: Subscription;

  constructor(private store: Store<rootState.AppState>) {}

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    this.eventKey = event.key;
  }

  ngOnInit(): void {
    this.dimensionsSubscription = this.store.pipe(
      select(rootState.getDimensions),
      map((dimensions: TireDimensions) => this.dimensions = dimensions)
    ).subscribe();

    setTimeout(() => {
      this.widthInput.nativeElement.focus();
    }, 50);
  }

  onWidthChanged(): void {
    if (this.widthInput) {
      this.store.dispatch(new SetDimensions(Object.assign({}, this.dimensions, {
        width: this.widthInput.nativeElement.value
      })));
    }

    if (this.dimensions.width.length === 3) {
      this.goToInput(this.heightInput);
    }
  }

  onHeightChanged(): void {
    if (this.heightInput) {
      this.store.dispatch(new SetDimensions(Object.assign({}, this.dimensions, {
        height: this.heightInput.nativeElement.value
      })));
    }

    if (this.dimensions.height.length === 2) {
      this.goToInput(this.diameterInput);
    }
  }

  onDiameterChanged(): void {
    if (this.diameterInput) {
      this.store.dispatch(new SetDimensions(Object.assign({}, this.dimensions, {
        diameter: this.diameterInput.nativeElement.value
      })));
    }
  }

  onFocus(field): void {
    setTimeout(() => {
      field.select();
    }, 50);
  }

  goToInput(input: ElementRef) {
    setTimeout(() => {
      if (this.eventKey !== 'Tab' && this.eventKey !== 'Shift') {
        input.nativeElement.focus();
      }
    }, 100);
  }

  ngOnDestroy(): void {
    if (this.dimensionsSubscription) {
      this.dimensionsSubscription.unsubscribe();
    }
  }
}

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { forkJoin, Observable, of, merge } from "rxjs";
import { LocalStorageService } from "ngx-webstorage";
import { catchError, map, switchMap, reduce } from "rxjs/operators";

@Injectable()
export class DataService {
  constructor(private _http: HttpClient, private _ls: LocalStorageService) {}

  searchByRegNo(
    wholesalerId: string,
    regNo: string,
    customerNumber: string
  ): Observable<any> {
    return this.search(
      wholesalerId,
      regNo,
      customerNumber,
      "searchLocallyByDimensions"
    );
  }

  searchLocallyByDimensions(
    wholesalerId: string,
    dimensions: string,
    customerNumber: string
  ): Observable<any> {
    return this.searchLocalFile(
      "../../assets/data/tires.json",
      wholesalerId,
      dimensions,
      customerNumber
    ).pipe(
      switchMap((tires) =>
        this.searchLocalFile(
          "../../assets/data/leftover-stock.json",
          wholesalerId,
          dimensions,
          customerNumber
        ).pipe(
          switchMap((leftovers: any[]) => {
            let result = [...tires, ...leftovers];
            if (result.length > 0) {
              let articles = result
                .map((x) => x.article_number_with_alpha)
                .join(";");

              return this.getDetails(articles, customerNumber).pipe(
                map((response: any) => {
                  let new_result = result.map(function (ele) {  
                    console.log('response', response, 'ele', ele);
                                      
                    return {
                      ...ele,
                      details: response
                        .filter(
                          (f) =>
                            f.article_with_alfa ==
                            ele.article_number_with_alpha
                        )
                        .map((obj) => obj.details)[0],
                    };
                  });                  
                  return new_result.filter((tire) => tire !== null);
                }),
                catchError((error) => {
                  return of(
                    Object.assign({}, result, {
                      error: "Kunne ikke hente detaljer fra tredjepart",
                    })
                  );
                })
              );
            }
          }),
          catchError((error) => {
            return of(
              Object.assign({}, error, {
                error: "Kunne ikke hente detaljer fra tredjepart",
              })
            );
          })
        )
      )
    );
  }

  searchLeftoverStockByRegNo(
    wholesalerId: string,
    regNo: string,
    customerNumber: string
  ): Observable<any> {
    return this.search(
      wholesalerId,
      regNo,
      customerNumber,
      "searchLeftoverStockLocallyByDimensions"
    );
  }

  searchLeftoverStockLocallyByDimensions(
    wholesalerId: string,
    dimensions: string,
    customerNumber: string
  ): Observable<any> {
    return this.searchLocalFile(
      "../../assets/data/leftover-stock.json",
      wholesalerId,
      dimensions,
      customerNumber
    );
  }

  search(
    wholesalerId: string,
    regNo: string,
    customerNumber: string,
    searchFunction: string
  ): Observable<any> {
    let result = { vehicle: null, tires: { front: null, back: null } };
    return this._http.get(`${environment["API_URL"]}vehicle/${regNo}`).pipe(
      switchMap((vehicle: any) => {
        if (vehicle.merkeNavn) {
          result = Object.assign({}, result, { vehicle: vehicle });

          const frontDimensions = this.parseDimensions(vehicle["stdDekkForan"]);
          const backDimensions = this.parseDimensions(vehicle["stdDekkBak"]);

          if (frontDimensions !== backDimensions) {
            return forkJoin([
              this[searchFunction](
                wholesalerId,
                frontDimensions,
                customerNumber
              ),
              this[searchFunction](
                wholesalerId,
                backDimensions,
                customerNumber
              ),
            ]);
          } else {
            return forkJoin([
              this[searchFunction](
                wholesalerId,
                frontDimensions,
                customerNumber
              ),
            ]);
          }
        } else {
          throw new Error(`Vi fant dessverre ingen bil med reg. nr. ${regNo}`);
        }
      }),
      map((values) =>
        Object.assign({}, result, {
          tires: { front: values[0], back: values[1] ? values[1] : null },
        })
      )
    );
  }

  searchLocalFile(
    file: string,
    wholesalerId: string,
    dimensions: string,
    customerNumber: string
  ): Observable<any> {
    return this._http
      .get(file, {
        headers: { Authorization: `Bearer ${this._getAuthToken()}` },
      })
      .pipe(
        map((json: any) => json.tires),
        map((tires: any[]) =>
          tires.filter((tire) =>
            tire.article_number_with_alpha.includes(dimensions)
          )
        ),
        map((results: any[]) => {
          if (results.length > 0) {
            return results;
          }
          return [];
        })
      );
  }

  getDetails(articleNumbers: string, customerNumber: string): Observable<any> {
    const url = `${environment["API_URL"]}ad-item/batchsearch/item/${articleNumbers}/${customerNumber}`;
    console.log(url);
    
    return this._http
      .get(url, {
        headers: { Authorization: `Bearer ${this._getAuthToken()}` },
      })
      .pipe(
        map((details: any) =>
          details.message.map(function (d) {
            return {
              details: d,
              article_number_with_alpha: d.artnr,
              article_with_alfa: d.article_with_alfa,
            };
          })
        )
      );
  }

  listTireImages(): Observable<any[]> {
    const url = `${environment["API_URL"]}image?folder=tires`;
    return this._http
      .get(url, {
        headers: { Authorization: `Bearer ${this._getAuthToken()}` },
      })
      .pipe(map((response: any[]) => response));
  }

  sendOrder(
    cartItems: any[],
    customerNo: string,
    wholesaleId: string,
    text?: string
  ): Observable<any> {
    const url = `${environment["API_URL"]}ad-item/${wholesaleId}/send-order`;
    const body = {
      wholesale_id: wholesaleId,
      customer_number: customerNo.toString(),
      app_id: environment.APP_ID,
      rows: cartItems.map((item) => ({
        amount: item.amount,
        article_number: item.tire.article_number_with_alpha,
        text,
      })),
    };

    return this._http
      .post(url, body, {
        headers: { Authorization: `Bearer ${this._getAuthToken()}` },
      })
      .pipe(map((_) => Object.assign({}, { text, cartItems })));
  }

  private parseDimensions(result: string): string {
    return result
      .replace(" XL", "")
      .replace(" C", "")
      .replace("/", "")
      .replace(/\s/g, "")
      .replace("R", "")
      .replace("-", "");
  }

  private _getAuthToken() {
    return this._ls.retrieve("dekksok-token");
  }
}

import {Injectable} from '@angular/core';
import {LocalStorageService} from 'ngx-webstorage';
import {Observable, of} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from '../../environments/environment';
import {map, switchMap, take} from 'rxjs/operators';
import * as jwt_decode from 'jwt-decode';
import * as moment from 'moment';
import {UnAuthenticate} from '../store/auth/auth.actions';
import {Store} from '@ngrx/store';
import * as rootState from '../store';

@Injectable()
export class AuthService {
  constructor(private _ls: LocalStorageService, private _http: HttpClient, private store: Store<rootState.AppState>) {}

  checkAuth(): Observable<any> {
    return of(this._ls.retrieve('dekksok-token'))
      .pipe(
        take(1),
        switchMap((token: string) => {
          if (token !== null) {
            const decodedToken: any = jwt_decode(token);
            const hasExpired = moment.unix(decodedToken.exp).isBefore(moment());

            if (hasExpired) {
              return this.unAuthenticate();
            } else {
              return this.getAuthUser(token, decodedToken.id);
            }
          } else {
            return of(this.store.dispatch(new UnAuthenticate));
          }
        })
      );
  }

  authenticate(workshopNumber: string, customerNumber: string): Observable<any> {
    const url = `${environment.API_URL}wholesale/customer-auth`;
    const body = {workshop_number: workshopNumber, customer_number: customerNumber, app_id: environment.APP_ID};
    return this._http.post(url, body)
      .pipe(
        map((response: any) => {
          this._ls.store('dekksok-token', response.token);
          return response.token;
        }),
        switchMap((token: string) => {
          const decodedToken: any = jwt_decode(token);
          return this.getAuthUser(token, decodedToken.id);
        })
      );
  }

  getAuthUser(token: string, workshopId: string): any {
    const url = `${environment.API_URL}workshop/${workshopId}`;
    let headers = new HttpHeaders();
    headers = headers.append('authorization', `Bearer ${token}`);

    return this._http.get(url, {headers});
  }

  unAuthenticate(): Observable<void> {
    return of(this._ls.store('dekksok-token', null));
  }

}

import {Action} from '@ngrx/store';

export const ADD_TO_CART = '[Cart] Add to cart';
export const REMOVE_FROM_CART = '[Cart] Add from cart';
export const TOGGLE_SHOW_CART = '[Cart] toggle show cart';
export const INCREASE_CART_AMOUNT = '[Cart] increase cart amount';
export const DECREASE_CART_AMOUNT = '[Cart] decrease cart amount';
export const RESET_CART = '[Cart] reset cart';

export class AddToCart implements Action {
  readonly type = ADD_TO_CART;

  constructor(public payload: {tire: any, amount: number, imageUrl: string}) {}
}

export class RemoveFromCart implements Action {
  readonly type = REMOVE_FROM_CART;

  constructor(public payload: any) {}
}

export class ToggleShowCart implements Action {
  readonly type = TOGGLE_SHOW_CART;

  constructor(public payload?: any) {}
}

export class IncreaseCartAmount implements Action {
  readonly type = INCREASE_CART_AMOUNT;

  constructor(public payload: any) {}
}

export class DecreaseCartAmount implements Action {
  readonly type = DECREASE_CART_AMOUNT;

  constructor(public payload: any) {}
}

export class ResetCart implements Action {
  readonly type = RESET_CART;

  constructor(public payload?: any) {}
}

export type All = AddToCart | RemoveFromCart | ToggleShowCart | IncreaseCartAmount | DecreaseCartAmount | ResetCart;

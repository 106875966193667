import * as ToastActions from './toast.actions';

export type Action = ToastActions.All;

export interface ToastState {
  message: {type: string, text: string},
}

export const initialState: ToastState = {
  message: null
};

export function reducer(state = initialState, {type, payload}: Action): ToastState {
  switch (type) {
    case ToastActions.ActionTypes.ShowToast:
      return {
        message: payload
      };

    case ToastActions.ActionTypes.ClearToast:
      return {
        message: null
      };
    default:
      return state;
  }
}

export const getMessage = (state) => state.message;

import {Component, OnInit, OnDestroy} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {select, Store} from '@ngrx/store';
import * as rootState from '../store';
import {SearchByDimensions, SearchByRegNo, SearchLeftoverStockByDimensions, SearchLeftoverStockByRegNo} from '../store/tire/tire.actions';
import {delay, map} from 'rxjs/operators';
import {TireDimensions} from '../interfaces';
import {Router} from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit, OnDestroy {
  isLoading: boolean;
  vehicle$: Observable<any>;
  resultByRegNo$: Observable<any>;
  resultByDimensions$: Observable<any>;
  selectedTab = 1;
  workshop: any;
  showLeftoverStock: boolean;
  leftoverStockResultsByRegNo$: Observable<any>;
  leftoverStockResultsByDimensions$: Observable<any>;

  private loadingSubscription: Subscription;
  private workshopSubscription: Subscription;
  private orderSubscription: Subscription;

  constructor(private store: Store<rootState.AppState>, private router: Router) {}

  ngOnInit(): void {
    this.loadingSubscription = this.store
      .pipe(
        delay(0),
        select(rootState.getTireLoadingState),
        map((isLoading: boolean) => this.isLoading = isLoading)
      )
      .subscribe();

    this.vehicle$ = this.store.pipe(select(rootState.getVehicle));
    this.resultByRegNo$ = this.store.pipe(select(rootState.getTireResultsByRegNo));
    this.resultByDimensions$ = this.store.pipe(select(rootState.getTireResultsByDimensions));

    this.leftoverStockResultsByRegNo$ = this.store.pipe(select(rootState.getLeftoverStockResultsByRegNo));
    this.leftoverStockResultsByDimensions$ = this.store.pipe(select(rootState.getLeftoverStockResultsByDimensions));

    this.workshopSubscription = this.store.pipe(select(rootState.getAuthUser))
      .subscribe((authUser) => this.workshop = authUser);

    this.orderSubscription = this.store
      .pipe(
        select(rootState.getOrder),
        map((order) => {
          if (order) {
            this.router.navigate(['confirmation']);
          }
        })
      )
      .subscribe();
  }

  toggleLeftoverStock(): void {
    this.showLeftoverStock = (!this.showLeftoverStock);
  }

  switchTab(): void {
    if (this.selectedTab === 1) {
      this.selectedTab = 2;
    } else if (this.selectedTab === 2) {
      this.selectedTab = 1;
    } else {
      this.selectedTab = 1;
    }
  }

  searchRegNo(regNo: string) {
    const data = {
      regNo: regNo,
      wholesalerId: this.workshop.wholesaler,
      customerNumber: this.workshop.customer_number
    };

    this.store.dispatch(new SearchByRegNo(data));
    this.store.dispatch(new SearchLeftoverStockByRegNo(data));
  }

  searchDimensions(dimensions: TireDimensions) {
    const data = {
      dimensions: `${dimensions.width}${dimensions.height}${dimensions.diameter}`,
      wholesalerId: this.workshop.wholesaler,
      customerNumber: this.workshop.customer_number
    };

    this.store.dispatch(new SearchByDimensions(data));
    this.store.dispatch(new SearchLeftoverStockByDimensions(data));
  }

  ngOnDestroy(): void {
    if (this.workshopSubscription) {
      this.workshopSubscription.unsubscribe();
    }

    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
    }

    if (this.orderSubscription) {
      this.orderSubscription.unsubscribe();
    }
  }
}

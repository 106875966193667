import {Action} from '@ngrx/store';
import {TireDimensions} from '../../interfaces';

export enum ActionTypes {
  SetRegNo = '[Search] Set reg no',
  SetDimensions = '[Search] Set dimensions',
}

export class SetRegNo implements Action {
  readonly type = ActionTypes.SetRegNo;

  constructor(public payload: string) {}
}

export class SetDimensions implements Action {
  readonly type = ActionTypes.SetDimensions;

  constructor(public payload: TireDimensions) {}
}

export type All = SetRegNo | SetDimensions;

import {Action} from '@ngrx/store';

export const AUTHENTICATE = '[Auth] Authenticate';
export const AUTHENTICATE_SUCCESS = '[Auth] Authenticate success';
export const AUTHENTICATE_FAILURE = '[Auth] Authenticate failure';
export const CHECK_AUTH = '[Auth] Check auth';
export const CHECK_AUTH_SUCCESS = '[Auth] Check auth success';
export const CHECK_AUTH_FAILURE = '[Auth] Check auth failure';
export const UNAUTHENTICATE = '[Auth] UnAuthenticate';
export const UNAUTHENTICATE_SUCCESS = '[Auth] UnAuthenticate success';

export class Authenticate implements Action {
  readonly type = AUTHENTICATE;

  constructor(public payload: {workshopNumber: string, customerNumber: string}) {}
}

export class AuthenticateSuccess implements Action {
  readonly type = AUTHENTICATE_SUCCESS;

  constructor(public payload: any) {}
}

export class AuthenticateFailure implements Action {
  readonly type = AUTHENTICATE_FAILURE;

  constructor(public payload: string) {}
}

export class CheckAuth implements Action {
  readonly type = CHECK_AUTH;

  constructor(public payload?: any) {}
}

export class CheckAuthSuccess implements Action {
  readonly type = CHECK_AUTH_SUCCESS;

  constructor(public payload: any) {}
}

export class CheckAuthFailure implements Action {
  readonly type = CHECK_AUTH_FAILURE;

  constructor(public payload: any) {}
}

export class UnAuthenticate implements Action {
  readonly type = UNAUTHENTICATE;

  constructor(public payload?: any) {}
}

export class UnAuthenticateSuccess implements Action {
  readonly type = UNAUTHENTICATE_SUCCESS;

  constructor(public payload: any) {}
}

export type All = Authenticate | AuthenticateSuccess | AuthenticateFailure | CheckAuth | CheckAuthSuccess | CheckAuthFailure |
  UnAuthenticate | UnAuthenticateSuccess;

import {Component, Input} from '@angular/core';
import {AddToCart} from '../../store/cart/cart.actions';
import {Store} from '@ngrx/store';
import * as rootState from '../../store';

@Component({
  selector: 'app-add-to-cart',
  template: `
    <form #orderForm="ngForm" class="add-to-card" (ngSubmit)="addToCart()">
      <label class="label">Antall</label>
      <app-amount-selector [amount]="amount" (increaseAmount)="increaseAmount()" (decreaseAmount)="decreaseAmount()"
                           (amountChanged)="amountChanged($event)">
      </app-amount-selector>

      <button type="submit" class="button button--small is-primary margin-top-sm" [disabled]="amount === 0">
        Legg i handlekurv
      </button>
    </form>
  `
})
export class AddToCartComponent {
  @Input() tire: any;
  @Input() imageUrl: string;

  amount = 0;

  constructor(private store: Store<rootState.AppState>) {}

  increaseAmount() {
    this.amount++;
  }

  decreaseAmount() {
    if (this.amount !== 0) {
      this.amount--;
    }
  }

  amountChanged(amount: number): void {
    if (amount > this.tire.details.available) {
      this.amount = this.tire.details.available;
    }
  }

  addToCart(): void {
    this.store.dispatch(new AddToCart({amount: this.amount, tire: this.tire, imageUrl: this.imageUrl}));
    this.amount = 0;
  }
}

import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {AuthService} from '../../core/auth.service';
import * as AuthActions from './auth.actions';
import {Observable, of} from 'rxjs';
import {Action} from '@ngrx/store';

@Injectable()
export class AuthEffects {

  @Effect() authenticate$: Observable<Action> = this.actions$
    .pipe(
      ofType(AuthActions.AUTHENTICATE),
      map((action: AuthActions.Authenticate) => action.payload),
      switchMap((payload) =>
        this.authService.authenticate(payload.workshopNumber, payload.customerNumber)
          .pipe(
            map(data => new AuthActions.AuthenticateSuccess(data)),
            catchError((error) => of(new AuthActions.AuthenticateFailure(error)))
          )
      ),
    );

  @Effect() checkAuth$: Observable<Action> = this.actions$
    .pipe(
      ofType(AuthActions.CHECK_AUTH),
      switchMap(_ =>
        this.authService.checkAuth()
          .pipe(
            map(data => new AuthActions.CheckAuthSuccess(data)),
            catchError((error) => of(new AuthActions.CheckAuthFailure(error)))
          )
      ),
    );

  @Effect() unAuthenticate$: Observable<Action> = this.actions$
    .pipe(
      ofType(AuthActions.UNAUTHENTICATE),
      switchMap(_ =>
        this.authService.unAuthenticate()
          .pipe(
            map(data => new AuthActions.UnAuthenticateSuccess(data)),
          )
      ),
    );

  constructor(private actions$: Actions, private authService: AuthService) {}
}

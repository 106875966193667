import {Action} from '@ngrx/store';

export const SEARCH_BY_REG_NO = '[Tire] Search by reg. no.';
export const SEARCH_BY_REG_NO_SUCCESS = '[Tire] Search by reg. no. success';
export const SEARCH_BY_REG_NO_FAILURE = '[Tire] Search by reg. no. failure';
export const SEARCH_BY_DIMENSIONS = '[Tire] Search by dimensions';
export const SEARCH_BY_DIMENSIONS_SUCCESS = '[Tire] Search by dimensions success';
export const SEARCH_BY_DIMENSIONS_FAILURE = '[Tire] Search by dimensions failure';
export const SEARCH_LEFTOVER_STOCK_BY_REG_NO = '[Tire] Search leftover stock by reg. no.';
export const SEARCH_LEFTOVER_STOCK_BY_REG_NO_SUCCESS = '[Tire] Search leftover stock by reg. no. success';
export const SEARCH_LEFTOVER_STOCK_BY_REG_NO_FAILURE = '[Tire] Search leftover stock by reg. no. failure';
export const SEARCH_LEFTOVER_STOCK_BY_DIMENSIONS = '[Tire] Search leftover stock by dimensions';
export const SEARCH_LEFTOVER_STOCK_BY_DIMENSIONS_SUCCESS = '[Tire] Search leftover stock by dimensions success';
export const SEARCH_LEFTOVER_STOCK_BY_DIMENSIONS_FAILURE = '[Tire] Search leftover stock by dimensions failure';
export const LOAD_TIRE_IMAGES = '[Tire] Load tire images';
export const LOAD_TIRE_IMAGES_SUCCESS = '[Tire] Load tire images success';
export const LOAD_TIRE_IMAGES_FAILURE = '[Tire] Load tire images failure';
export const SEND_ORDER = '[Tire] send order';
export const SEND_ORDER_SUCCESS = '[Tire] send order success';
export const SEND_ORDER_FAILURE = '[Tire] send order failure';
export const CLEAR_ORDER = '[Tire] clear order';

export class SearchByRegNo implements Action {
  readonly type = SEARCH_BY_REG_NO;

  constructor(public payload: {regNo: string, wholesalerId: string, customerNumber: string}) {}
}

export class SearchByRegNoSuccess implements Action {
  readonly type = SEARCH_BY_REG_NO_SUCCESS;

  constructor(public payload: any) {}
}

export class SearchByRegNoFailure implements Action {
  readonly type = SEARCH_BY_REG_NO_FAILURE;

  constructor(public payload?: any) {}
}

export class SearchByDimensions implements Action {
  readonly type = SEARCH_BY_DIMENSIONS;

  constructor(public payload: {dimensions: string, wholesalerId: string, customerNumber: string}) {}
}

export class SearchByDimensionsSuccess implements Action {
  readonly type = SEARCH_BY_DIMENSIONS_SUCCESS;

  constructor(public payload: any) {}
}

export class SearchByDimensionsFailure implements Action {
  readonly type = SEARCH_BY_DIMENSIONS_FAILURE;

  constructor(public payload?: any) {}
}

export class SearchLeftoverStockByRegNo implements Action {
  readonly type = SEARCH_LEFTOVER_STOCK_BY_REG_NO;

  constructor(public payload: {regNo: string, wholesalerId: string, customerNumber: string}) {}
}

export class SearchLeftoverStockByRegNoSuccess implements Action {
  readonly type = SEARCH_LEFTOVER_STOCK_BY_REG_NO_SUCCESS;

  constructor(public payload: any) {}
}

export class SearchLeftoverStockByRegNoFailure implements Action {
  readonly type = SEARCH_LEFTOVER_STOCK_BY_REG_NO_FAILURE;

  constructor(public payload?: any) {}
}

export class SearchLeftoverStockByDimensions implements Action {
  readonly type = SEARCH_LEFTOVER_STOCK_BY_DIMENSIONS;

  constructor(public payload: {dimensions: string, wholesalerId: string, customerNumber: string}) {}
}

export class SearchLeftoverStockByDimensionsSuccess implements Action {
  readonly type = SEARCH_LEFTOVER_STOCK_BY_DIMENSIONS_SUCCESS;

  constructor(public payload: any) {}
}

export class SearchLeftoverStockByDimensionsFailure implements Action {
  readonly type = SEARCH_LEFTOVER_STOCK_BY_DIMENSIONS_FAILURE;

  constructor(public payload?: any) {}
}

export class LoadTireImages implements Action {
  readonly type = LOAD_TIRE_IMAGES;

  constructor(public payload?: any) {}
}

export class LoadTireImagesSuccess implements Action {
  readonly type = LOAD_TIRE_IMAGES_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadTireImagesFailure implements Action {
  readonly type = LOAD_TIRE_IMAGES_FAILURE;

  constructor(public payload?: any) {}
}

export class SendOrder implements Action {
  readonly type = SEND_ORDER;

  constructor(public payload: {items: any[], customerNo: string, wholesaleId: string, text?: string}) {}
}

export class SendOrderSuccess implements Action {
  readonly type = SEND_ORDER_SUCCESS;

  constructor(public payload: any) {}
}

export class SendOrderFailure implements Action {
  readonly type = SEND_ORDER_FAILURE;

  constructor(public payload: string) {}
}

export class ClearOrder implements Action {
  readonly type = CLEAR_ORDER;

  constructor(public payload?: string) {}
}

export type All = SearchByRegNo | SearchByRegNoSuccess | SearchByRegNoFailure | SearchByDimensions | SearchByDimensionsSuccess |
  SearchByDimensionsFailure | SearchLeftoverStockByRegNo | SearchLeftoverStockByRegNoSuccess | SearchLeftoverStockByRegNoFailure |
  SearchLeftoverStockByDimensions | SearchLeftoverStockByDimensionsSuccess | SearchLeftoverStockByDimensionsFailure |
  LoadTireImages | LoadTireImagesSuccess | LoadTireImagesFailure | SendOrder | SendOrderSuccess | SendOrderFailure | ClearOrder;

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {VehicleInfoComponent} from './vehicle-info/vehicle-info.component';
import {TiresComponent} from './tires/tires.component';
import {TireComponent} from './tire/tire.component';
import {FormsModule} from '@angular/forms';
import {ToastComponent} from './toast/toast.component';
import {CartComponent} from './cart/cart.component';
import {AmountSelectorComponent} from './amount-selector/amount-selector.component';
import {RegnoSearchBoxComponent} from './regno-search-box/regno-search-box.component';
import {DimensionsSearchBoxComponent} from './dimensions-search-box/dimensions-search-box.component';
import {LoaderComponent} from './loader.component';
import {AddToCartComponent} from './add-to-cart/add-to-cart-component';
import {LeftoverStockButtonComponent} from './leftover-stock-button.component';

@NgModule({
  declarations: [
    VehicleInfoComponent,
    TiresComponent,
    TireComponent,
    ToastComponent,
    CartComponent,
    AmountSelectorComponent,
    RegnoSearchBoxComponent,
    DimensionsSearchBoxComponent,
    LoaderComponent,
    AddToCartComponent,
    LeftoverStockButtonComponent
  ],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [
    VehicleInfoComponent,
    TiresComponent,
    TireComponent,
    ToastComponent,
    CartComponent,
    AmountSelectorComponent,
    RegnoSearchBoxComponent,
    DimensionsSearchBoxComponent,
    LoaderComponent,
    AddToCartComponent,
    LeftoverStockButtonComponent
  ]
})
export class SharedModule {}

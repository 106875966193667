import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-vehicle-info',
  templateUrl: './vehicle-info.component.html'
})
export class VehicleInfoComponent {
  @Input() vehicle: any;

  moreInfo: boolean;

  showMoreInfo(): void {
    this.moreInfo = !this.moreInfo;
  }
}

import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import * as rootState from '../../store';
import {Store} from '@ngrx/store';
import {map} from 'rxjs/operators';
import {ClearToast} from '../../store/toast/toast.actions';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html'
})
export class ToastComponent implements OnInit {
  message$: Observable<{type: string, text: string}>;

  constructor(private _store: Store<rootState.AppState>) {}

  ngOnInit(): void {
    this.message$ = this._store.select(rootState.getToastMessage)
      .pipe(
        map(toast => {
          setTimeout(() => {
            this._store.dispatch(new ClearToast());
          }, 5000);

          return toast;
        })
      );
  }
}

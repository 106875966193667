import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '../core/auth.service';
import {Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import * as rootState from '../store';
import {Authenticate} from '../store/auth/auth.actions';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit, OnDestroy {
  title = 'Logg inn';
  workshopNumber: string;
  customerNumber: string;
  error: string;
  isLoading: boolean;

  private loadingSubscription: Subscription;
  private errorSubscription: Subscription;

  constructor(private _auth: AuthService, private _router: Router, private _store: Store<rootState.AppState>) {}

  ngOnInit(): void {
    this.loadingSubscription = this._store
      .pipe(select(rootState.getAuthLoadingState))
      .subscribe((isLoading) => this.isLoading = isLoading);

    this.errorSubscription = this._store.pipe(select(rootState.getAuthError)).subscribe(error => this.error = error);
  }

  login(): void {
    this._store.dispatch(new Authenticate({workshopNumber: this.workshopNumber, customerNumber: this.customerNumber}));
  }

  ngOnDestroy(): void {
    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
    }
  }

}

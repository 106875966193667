import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-leftover-stock-button',
  template: `
    <div>
      <label class="button-checkbox">
        <input type="checkbox" [ngModel]="showLeftoverStock" (ngModelChange)="toggleLeftoverStock.emit()" [disabled]="disabled">
        <span>Vis restlager</span>
      </label>
    </div>
  `
})
export class LeftoverStockButtonComponent {
  @Input() showLeftoverStock: boolean;
  @Input() disabled: boolean;
  @Output() toggleLeftoverStock: EventEmitter<void> = new EventEmitter<void>();
}

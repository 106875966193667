import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {switchMap} from 'rxjs/operators';
import * as TireActions from '../tire/tire.actions';
import * as CartActions from './cart.actions';
import {Observable, of} from 'rxjs';
import {Action} from '@ngrx/store';
import {ShowToast} from '../toast/toast.actions';

@Injectable()
export class CartEffects {

  @Effect() emptyOnSuccess$: Observable<Action> = this.actions$
    .pipe(
      ofType(TireActions.SEND_ORDER_SUCCESS),
      switchMap(res => [
        new CartActions.ResetCart(),
        new ShowToast({type: 'SUCCESS', text: 'Bestilling sent!'})
      ])
    );

  constructor(private actions$: Actions) {}
}

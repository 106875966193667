import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as rootState from '../../store';
import {LoadTireImages} from '../../store/tire/tire.actions';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-tires',
  templateUrl: './tires.component.html'
})
export class TiresComponent implements OnInit, OnChanges {
  @Input() tires: Array<any>;
  @Input() heading: string;
  filtered: {SUMMER: any[], WINTER: any[], PIG: any[]} = {'SUMMER': [], 'WINTER': [], 'PIG': []};
  filters = {
    'SUMMER': ['SOM', 'SOC', 'SO4'],
    'WINTER': ['VIN', 'VIC', 'VI4', 'HE4'],
    'PIG': ['PIG', 'PIC', 'PI4']
  };
  selectedFilter: string = null;
  images$: Observable<any[]>;
  showPrice: boolean;
  selectedSort: string;
  selectedSortDirection: string;

  constructor(private store: Store<rootState.AppState>) {}

  ngOnInit(): void {
    this.images$ = this.store.pipe(select(rootState.getTireImages));
    this.store.dispatch(new LoadTireImages());
  }

  ngOnChanges(): void {
    if (this.tires) {
      this.filtered = this.sortTiresBySeason(this.tires);      
      this.setSort('price');
      this.setSortDirection('low');
    }
  }

  setFilter(filter: string): void {
    this.selectedFilter = filter;
    this.sort();
  }

  setSort(sort: string): void {
    this.selectedSort = sort;
    this.sort();
  }

  setSortDirection(direction: string): void {
    this.selectedSortDirection = direction;
    this.sort();
  }

  sortTiresBySeason(tires: any[]): {SUMMER: any[], WINTER: any[], PIG: any[]} {
    const filtered = {SUMMER: [], WINTER: [], PIG: []};

    for (let i = 0; i < tires.length; i++) {
      const tire = tires[i];

      for (const season in this.filters) {
        if (this.filters.hasOwnProperty(season)) {
          for (let k = 0; k < this.filters[season].length; k++) {
            const filter = this.filters[season][k];

            if (tire.article_number_with_alpha.includes(filter)) {
              filtered[season] = [].concat(...filtered[season], tire);
            }
          }
        }
      }
    }

    return filtered;
  }

  private sort(): void {
    this.tires = this.sortByDetail(this.selectedSort, this.tires, this.selectedSortDirection, this.selectedSort !== 'price');

    if (this.selectedFilter) {
      for (const season in this.filters) {
        if (this.filters.hasOwnProperty(season)) {
          this.filtered[season] = this.sortByDetail(this.selectedSort, this.filtered[season],
            this.selectedSortDirection, this.selectedSort !== 'price');
        }
      }
    }
  }

  private sortByDetail(key: string, array: any[], direction: string, isDetail: boolean = true): any[] {
    if (isDetail) {
      return array.sort((a: any, b: any) => {
        if (direction === 'low') {
          return (a.details[key] > b.details[key]) ? 1 : -1;
        } else if (direction === 'high') {
          return (a.details[key] < b.details[key]) ? 1 : -1;
        }
      });
    } else {
      return array.sort((a: any, b: any) => {
        if (direction === 'low') {
          return (a[key] > b[key]) ? 1 : -1;
        } else if (direction === 'high') {
          return (a[key] < b[key]) ? 1 : -1;
        }
      });
    }
  }
}

import {NgModule} from '@angular/core';
import {DataService} from './data.service';
import {AuthService} from './auth.service';

@NgModule({
  providers: [
    DataService,
    AuthService
  ]
})
export class CoreModule {}

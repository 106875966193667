import {Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Observable, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import * as rootState from './store';
import {select, Store} from '@ngrx/store';
import {AuthenticationState} from './enums';
import {CheckAuth, UnAuthenticate} from './store/auth/auth.actions';
import {ToggleShowCart} from './store/cart/cart.actions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  cartItems$: Observable<any[]>;
  currentUrl: string;
  authUser$: Observable<any>;

  private routerEventSubscription: Subscription;

  constructor(private router: Router, private store: Store<rootState.AppState>) {}

  ngOnInit(): void {
    this.getCurrentUrl();
    this.cartItems$ = this.store.pipe(select(rootState.getCartItems));
    this.handleAuth();
  }

  toggleCart(): void {
    this.store.dispatch(new ToggleShowCart());
  }

  logout(): void {
    this.store.dispatch(new UnAuthenticate());
  }

  ngOnDestroy(): void {
    if (this.routerEventSubscription) {
      this.routerEventSubscription.unsubscribe();
    }
  }

  private getCurrentUrl(): void {
    this.routerEventSubscription = this.router.events
      .pipe(
        map((event) => {
          if (event instanceof NavigationEnd) {
            this.currentUrl = event.url;
          }
        })
      )
      .subscribe();
  }

  private handleAuth(): void {
    this.authUser$ = this.store.pipe(select(rootState.getAuthUser));

    const ignoreRoutes = ['/home', '/confirmation'];

    this.store
      .pipe(
        select(rootState.getAuthenticationState),
        map((authenticationState: AuthenticationState) => {
          if (authenticationState !== null) {
            if (authenticationState === AuthenticationState.UNAUTHENTICATED) {
              this.router.navigate(['login']);
            } else if (authenticationState === AuthenticationState.AUTHENTICATED && this.currentUrl) {
              const doIgnore = ignoreRoutes.indexOf(this.currentUrl) !== -1;

              if (!doIgnore) {
                this.router.navigate(['home']);
              }
            }
          }
        }),
      ).subscribe();

    this.store.dispatch(new CheckAuth());
  }
}

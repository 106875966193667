import {createSelector} from 'reselect';
import {ActionReducer, ActionReducerMap, MetaReducer} from '@ngrx/store';
import * as fromCart from './cart/cart.reducer';
import * as fromToast from './toast/toast.reducer';
import * as fromAuth from './auth/auth.reducer';
import * as fromTire from './tire/tire.reducer';
import * as fromSearch from './search/search.reducer';
import {UNAUTHENTICATE} from './auth/auth.actions';

export interface AppState {
  cart: fromCart.CartState,
  toast: fromToast.ToastState,
  auth: fromAuth.AuthState,
  tire: fromTire.TireState,
  search: fromSearch.SearchState
}

export const reducers: ActionReducerMap<AppState> = {
  cart: fromCart.reducer,
  toast: fromToast.reducer,
  auth: fromAuth.reducer,
  tire: fromTire.reducer,
  search: fromSearch.reducer
};

export function resetReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    if (action.type === UNAUTHENTICATE) {
      state = undefined;
    }

    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<any>[] = [resetReducer];

export const getCartState = (state: AppState) => state.cart;
export const getCartItems = createSelector(getCartState, fromCart.getItems);
export const getShowCart = createSelector(getCartState, fromCart.getShowCart);

export const getToastState = (state: AppState) => state.toast;
export const getToastMessage = createSelector(getToastState, fromToast.getMessage);

export const getAuthState = (state: AppState) => state.auth;
export const getAuthLoadingState = createSelector(getAuthState, fromAuth.getLoadingState);
export const getAuthUser = createSelector(getAuthState, fromAuth.getAuthUser);
export const getAuthError = createSelector(getAuthState, fromAuth.getError);
export const getAuthenticationState = createSelector(getAuthState, fromAuth.getAuthenticationState);

export const getTireState = (state: AppState) => state.tire;
export const getTireLoadingState = createSelector(getTireState, fromTire.getLoadingState);
export const getTireResultsByRegNo = createSelector(getTireState, fromTire.getResultByRegNo);
export const getTireResultsByDimensions = createSelector(getTireState, fromTire.getResultByDimensions);
export const getLeftoverStockResultsByRegNo = createSelector(getTireState, fromTire.getLeftoverStockResultsByRegNo);
export const getLeftoverStockResultsByDimensions = createSelector(getTireState, fromTire.getLeftoverStockResultsByDimensions);
export const getVehicle = createSelector(getTireState, fromTire.getVehicle);
export const getTiresByArticleNumberWithAlpha = createSelector(getTireState, fromTire.getTiresByArticleNumberWithAlpha);
export const getLoadingArticleNumber = createSelector(getTireState, fromTire.getLoadingArticleNumber);
export const getTireImages = createSelector(getTireState, fromTire.getTireImages);
export const getTireError = createSelector(getTireState, fromTire.getError);
export const getSendingOrderLoadingState = createSelector(getTireState, fromTire.getSendingOrderLoadingState);
export const getOrder = createSelector(getTireState, fromTire.getOrder);

export const getSearchState = (state: AppState) => state.search;
export const getRegNo = createSelector(getSearchState, fromSearch.getRegNo);
export const getDimensions = createSelector(getSearchState, fromSearch.getDimensions);

import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import * as TireActions from './tire.actions';
import {Observable, of} from 'rxjs';
import {Action} from '@ngrx/store';
import {DataService} from '../../core/data.service';
import {ShowToast} from '../toast/toast.actions';

@Injectable()
export class TireEffects {

  @Effect() searchByRegNo$: Observable<Action> = this.actions$
    .pipe(
      ofType(TireActions.SEARCH_BY_REG_NO),
      map((action: TireActions.SearchByRegNo) => action.payload),
      switchMap((payload) =>
        this.dataService.searchByRegNo(payload.wholesalerId, payload.regNo, payload.customerNumber)
          .pipe(
            map(data => new TireActions.SearchByRegNoSuccess(data)),
            catchError((error) => of(new TireActions.SearchByRegNoFailure(error)))
          )
      ),
    );

  @Effect() searchByRegNoFailure$: Observable<Action> = this.actions$
    .pipe(
      ofType(TireActions.SEARCH_BY_REG_NO_FAILURE),
      map((action: TireActions.SearchByRegNoFailure) => action.payload),
      switchMap((payload) => {
        let error = 'Noe gikk galt. Vennligst prøv igjen.';

        if (payload.error) {
          error = (payload.error.message === 'Service Unavailable') ? 'En ekstern tjeneste er ikke tilgjengelig for øyeblikket' : payload.error.message;
        } else if (payload.message) {
          error = payload.message;
        }

        return of(new ShowToast({type: 'ERROR', text: error}));
      }),
    );

  @Effect() searchByDimensions$: Observable<Action> = this.actions$
    .pipe(
      ofType(TireActions.SEARCH_BY_DIMENSIONS),
      map((action: TireActions.SearchByDimensions) => action.payload),
      switchMap((payload) =>
        this.dataService.searchLocallyByDimensions(payload.wholesalerId, payload.dimensions, payload.customerNumber)
          .pipe(
            map(data => new TireActions.SearchByDimensionsSuccess(data)),
            catchError((error) => of(new TireActions.SearchByDimensionsFailure(error)))
          )
      ),
    );

  @Effect() searchByDimensionsFailure$: Observable<Action> = this.actions$
    .pipe(
      ofType(TireActions.SEARCH_BY_DIMENSIONS_FAILURE),
      map((action: TireActions.SearchByDimensionsFailure) => action.payload),
      switchMap((payload) => {
        let error = 'Noe gikk galt. Vennligst prøv igjen.';

        if (payload.error.message) {
          error = (payload.error.message === 'Service Unavailable')
            ? 'En ekstern tjeneste er ikke tilgjengelig for øyeblikket' : payload.error.message;
        }

        return of(new ShowToast({type: 'ERROR', text: error}));
      }),
    );

  @Effect() searchLeftoverStockByRegNo$: Observable<Action> = this.actions$
    .pipe(
      ofType(TireActions.SEARCH_LEFTOVER_STOCK_BY_REG_NO),
      map((action: TireActions.SearchLeftoverStockByRegNo) => action.payload),
      switchMap((payload) =>
        this.dataService.searchLeftoverStockByRegNo(payload.wholesalerId, payload.regNo, payload.customerNumber)
          .pipe(
            map(data => new TireActions.SearchLeftoverStockByRegNoSuccess(data)),
            catchError((error) => of(new TireActions.SearchLeftoverStockByRegNoFailure(error)))
          )
      ),
    );

  @Effect() searchLeftoverStockByDimensions$: Observable<Action> = this.actions$
    .pipe(
      ofType(TireActions.SEARCH_LEFTOVER_STOCK_BY_DIMENSIONS),
      map((action: TireActions.SearchLeftoverStockByDimensions) => action.payload),
      switchMap((payload) =>
        this.dataService.searchLeftoverStockLocallyByDimensions(payload.wholesalerId, payload.dimensions, payload.customerNumber)
          .pipe(
            map(data => new TireActions.SearchLeftoverStockByDimensionsSuccess(data)),
            catchError((error) => of(new TireActions.SearchLeftoverStockByDimensionsFailure(error)))
          )
      ),
    );

  @Effect() loadTireImages$: Observable<Action> = this.actions$
    .pipe(
      ofType(TireActions.LOAD_TIRE_IMAGES),
      switchMap((_) =>
        this.dataService.listTireImages()
          .pipe(
            map(data => new TireActions.LoadTireImagesSuccess(data)),
            catchError((error) => of(new TireActions.LoadTireImagesFailure(error)))
          )
      )
    );

  @Effect() sendOrder$: Observable<Action> = this.actions$
    .pipe(
      ofType(TireActions.SEND_ORDER),
      map((action: TireActions.SendOrder) => action.payload),
      switchMap((payload) =>
        this.dataService.sendOrder(payload.items, payload.customerNo, payload.wholesaleId, payload.text)
          .pipe(
            map(data => new TireActions.SendOrderSuccess(data)),
            catchError((error) => of(new TireActions.SendOrderFailure(error)))
          )
      ),
    );

  constructor(private actions$: Actions, private dataService: DataService) {}
}

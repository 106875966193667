import {Action} from '@ngrx/store';

export enum ActionTypes {
  ShowToast = '[Toast] Show toast',
  ClearToast = '[Toast] Clear toast',
}

export class ShowToast implements Action {
  readonly type = ActionTypes.ShowToast;

  constructor(public payload: {type: string, text: string}) {}
}

export class ClearToast implements Action {
  readonly type = ActionTypes.ClearToast;

  constructor(public payload?: any) {}
}

export type All = ShowToast| ClearToast;

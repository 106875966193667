import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subscription} from 'rxjs';
import {select, Store} from '@ngrx/store';
import * as rootState from '../../store';
import {map} from 'rxjs/operators';
import {SetRegNo} from '../../store/search/search.actions';

@Component({
  selector: 'app-reg-no-search-box',
  template: `
    <form #regNoSearchForm="ngForm" class="regno-search-box" (ngSubmit)="searchRegNo.emit(regNo)" novalidate>
      <div class="regno-search-box-title">Søk etter registreringsnummer</div>
      <div class="regno-search-box-input-wrapper">
        <input type="text" class="regno-search-box-input" name="regno"
               autofocus autocomplete="off" [ngModel]="regNo" (ngModelChange)="setRegNo($event)" required [disabled]="disabled">
        <button type="submit" class="regno-search-box-button" [disabled]="!regNo && regNo?.length < 3 || disabled">
          Søk
        </button>
      </div>
    </form>
  `
})
export class RegnoSearchBoxComponent implements OnInit, OnDestroy {
  @Input() disabled: boolean;
  @Output() searchRegNo: EventEmitter<string> = new EventEmitter<string>();

  regNo: string;

  private regNoSubscription: Subscription;

  constructor(private store: Store<rootState.AppState>) {}

  ngOnInit(): void {
    this.regNoSubscription = this.store.pipe(
      select(rootState.getRegNo),
      map((regNo) => this.regNo = regNo)
    ).subscribe();
  }

  setRegNo(regNo): void {
    this.store.dispatch(new SetRegNo(regNo));
  }

  ngOnDestroy(): void {
    if (this.regNoSubscription) {
      this.regNoSubscription.unsubscribe();
    }
  }
}

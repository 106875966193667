import * as SearchActions from './search.actions';
import {TireDimensions} from '../../interfaces';

export type Action = SearchActions.All;

export interface SearchState {
  regNo: string;
  dimensions: TireDimensions;
}

export const initialState: SearchState = {
  regNo: null,
  dimensions: null
};

export function reducer(state = initialState, {type, payload}: Action): SearchState {
  switch (type) {
    case SearchActions.ActionTypes.SetRegNo:
      return Object.assign({}, state, {
        regNo: payload
      });
    case SearchActions.ActionTypes.SetDimensions:
      return Object.assign({}, state, {
        dimensions: payload
      });
    default:
      return state;
  }
}

export const getRegNo = (state) => state.regNo;
export const getDimensions = (state) => state.dimensions;

import * as TireActions from './tire.actions';

export type Action = TireActions.All;

export interface TireState {
  loading: boolean;
  resultByRegNo: {front: any[], back: any[]};
  resultByDimensions: any[];
  leftoverResultByRegNo: {front: any[], back: any[]};
  leftoverResultByDimensions: any[];
  tiresByArticleNumberWithAlpha: any;
  loadingArticleNumber: string;
  tireImages: any[];
  vehicle: any;
  error: any;
  sendingOrder: boolean;
  order: any[];
}

export const initialState: TireState = {
  loading: false,
  resultByRegNo: null,
  resultByDimensions: null,
  leftoverResultByRegNo: null,
  leftoverResultByDimensions: null,
  tiresByArticleNumberWithAlpha: null,
  loadingArticleNumber: null,
  tireImages: null,
  vehicle: null,
  error: null,
  sendingOrder: false,
  order: null
};

export function reducer(state = initialState, {type, payload}: Action): TireState {
  switch (type) {
    case TireActions.SEARCH_BY_REG_NO:
      return Object.assign({}, state, {
        loading: true,
        vehicle: null,
        resultByRegNo: null,
        resultByDimensions: null,
        error: null,
      });
    case TireActions.SEARCH_BY_REG_NO_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        resultByRegNo: payload.tires,
        vehicle: payload.vehicle,
      });
    case TireActions.SEARCH_BY_REG_NO_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    case TireActions.SEARCH_BY_DIMENSIONS:
      return Object.assign({}, state, {
        loading: true,
        error: null,
      });
    case TireActions.SEARCH_BY_DIMENSIONS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        resultByDimensions: payload,
      });
    case TireActions.SEARCH_BY_DIMENSIONS_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: payload.error
      });
    case TireActions.SEARCH_LEFTOVER_STOCK_BY_REG_NO_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        leftoverResultByRegNo: payload.tires,
        vehicle: payload.vehicle,
      });
    case TireActions.SEARCH_LEFTOVER_STOCK_BY_DIMENSIONS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        leftoverResultByDimensions: payload,
      });
    case TireActions.LOAD_TIRE_IMAGES:
      return Object.assign({}, state, {
        error: null,
      });
    case TireActions.LOAD_TIRE_IMAGES_SUCCESS:
      return Object.assign({}, state, {
        tireImages: payload,
      });
    case TireActions.LOAD_TIRE_IMAGES_FAILURE:
      return Object.assign({}, state, {
        error: payload.error
      });
    case TireActions.SEND_ORDER:
      return Object.assign({}, state, {
        sendingOrder: true
      });
    case TireActions.SEND_ORDER_SUCCESS:
      return Object.assign({}, state, {
        sendingOrder: false,
        order: payload
      });
    case TireActions.SEND_ORDER_FAILURE:
      return Object.assign({}, state, {
        sendingOrder: false,
        error: payload.error
      });
    case TireActions.CLEAR_ORDER:
      return Object.assign({}, state, {
        order: null
      });
    default:
      return state;
  }
}

export const getLoadingState = (state) => state.loading;
export const getResultByRegNo = (state) => state.resultByRegNo;
export const getResultByDimensions = (state) => state.resultByDimensions;
export const getLeftoverStockResultsByRegNo = (state) => state.leftoverResultByRegNo;
export const getLeftoverStockResultsByDimensions = (state) => state.leftoverResultByDimensions;
export const getVehicle = (state) => state.vehicle;
export const getTiresByArticleNumberWithAlpha = (state) => state.tiresByArticleNumberWithAlpha;
export const getLoadingArticleNumber = (state) => state.loadingArticleNumber;
export const getTireImages = (state) => state.tireImages;
export const getError = (state) => state.error;
export const getSendingOrderLoadingState = (state) => state.sendingOrder;
export const getOrder = (state) => state.order;
